import React from 'react'
import {Link} from 'gatsby'
import merci from '../assets/images/merci.jpg'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import { Helmet } from 'react-helmet'


const DemandePriseEnCompte = () => {
  return (
    <Layout>
        <Helmet>
          <title>Seekreet | Demande prise en compte</title>
          <meta name="description" content="Merci pour la saisie du formulaire. Votre demande a bien été prise en compte. Retour à l’accueil." />
        </Helmet>
      <Navbar />
      <div className="ptb-100">
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="center">
                      <div className="logo">
                          <img src={merci} alt="Merci" width="400"/>
                      </div>
                      <h2 className='ptb-30'>
                        Votre demande a bien été prise en compte. 
                        <br /><br />
                        L'équipe Seekreet.
                      </h2>
                      <Link to="/">Retour à l'accueil</Link>
                  </div>
              </div>
          </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default DemandePriseEnCompte